const host = 'https://api.test-t6dnbai-e5j3o4xzi6mes.de-2.platformsh.site/';

export default {
  domain: host,
  apiBase: `${host}api/v1/`,
  baseName: '/',
  googleMapsApikey: 'AIzaSyDS90V5Lkqnaj2U0a3BSy7TDJJVEa-W6mQ',
  language: 'sv',
  markerDestinationId: '619ca345ded0e203be4c90e1',
  appUrl: 'https://test-t6dnbai-e5j3o4xzi6mes.de-2.platformsh.site',
  wordpressUrl: 'https://gigassembly03.wpengine.com',
};
