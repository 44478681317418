import Routes from './Routes';
// File Types
import { FileTypes } from './FileTypes';
// images
import Star from '../assets/images/star.svg';
import Pin from '../assets/images/pin.svg';
import CircleX from '../assets/images/circle-x.svg';
import ClockIcon from '../assets/images/ClockIcon.svg';
import SkypeIcon from '../assets/images/skypeIcon.svg';
import LogoBankID from '../assets/images/LogoBankID.svg';
import ArrowDown from '../assets/images/Chevron-bottom.svg';
import StarEmpty from '../assets/images/Star-empty.svg';
import ThreeDots from '../assets/images/ThreeDots.svg';
import Delete from '../assets/images/Delete.svg';
import LogoGold from '../assets/images/GA_wordmark_novare_Black.svg';
import Search from '../assets/images/Search.svg';
import ArrowLeft from '../assets/images/ArrowLeft.svg';
import ArrowRight from '../assets/images/ArrowRight.svg';
import Calendar from '../assets/images/Calendar.svg';
import EditIcon from '../assets/images/Edit.svg';
import EyeIcon from '../assets/images/Eye.svg';
import ErrorIcon from '../assets/images/ErrorTraingle.svg';
import MenuIcon from '../assets/images/MenuIcon.svg';
import CircleTick from '../assets/images/CircleTick.svg';
import Download from '../assets/images/Download.svg';
import FlameIcon from '../assets/images/Flame.svg';
import FlashIcon from '../assets/images/FlashIcon.svg';
import Mail from '../assets/images/Mail.svg';
import Paperclip from '../assets/images/Paperclip.svg';
import Phone from '../assets/images/Phone.svg';
import PinEmpty from '../assets/images/PinEmpty.svg';
import BarChart from '../assets/images/BarChart.svg';
import Brush from '../assets/images/Brush.svg';
import CaseIcon from '../assets/images/CaseIcon.svg';
import DoubleTick from '../assets/images/DoubleTick.svg';
import Glasses from '../assets/images/Glasses.svg';
import SecurityVerifiedIcon from '../assets/images/SecurityVerifiedIcon.svg';
import BankIDIcon from '../assets/images/BankIDIcon.svg';
import HalfStar from '../assets/images/HalfStar.svg';
import LinkedIn from '../assets/images/LinkedIn.svg';
import InfoIcon from '../assets/images/InfoIcon.svg';
import CrossNew1 from '../assets/images/cross-new-1.svg';
import Loader from '../assets/images/Loader.svg';
import Plus from '../assets/images/plus.svg';
import Avtar from '../assets/images/Avtar.svg';
import GreyBorderStar from '../assets/images/GreyBorderStar.svg';
import Trash from '../assets/images/trash.svg';
import ExportIcon from '../assets/images/Vector.svg';
import SearchIcon from '../assets/images/searchIcon.svg';
import FilterIcon from '../assets/images/filter.svg';
import HeaderIcon from '../assets/images/GA_wordmark_novare_Black-2.svg';
import MobileHeaderIcon from '../assets/images/GA_wordmark_black-1-1.svg';

const language = localStorage.getItem('language')
  ? localStorage.getItem('language') === 'en'
    ? require('../translations/en.json')
    : require('../translations/sv.json')
  : require('../translations/sv.json');

const Constants = {
  ApplicationName: 'GigAssembly',
  TitleDivder: ' | ',
  DomainName: '/',
  routes: Routes,
  language,
  apiCredentials: {},
  end_points: {},
  pageTitle: {},
  icons: {
    Star,
    Pin,
    Trash,
    Avtar,
    Plus,
    ClockIcon,
    FilterIcon,
    SearchIcon,
    ExportIcon,
    SkypeIcon,
    CircleX,
    LogoBankID,
    Loader,
    StarEmpty,
    ArrowDown,
    ThreeDots,
    Delete,
    GreyBorderStar,
    Search,
    ArrowLeft,
    ArrowRight,
    Calendar,
    EditIcon,
    ErrorIcon,
    EyeIcon,
    MenuIcon,
    CircleTick,
    Download,
    FlameIcon,
    FlashIcon,
    Mail,
    Paperclip,
    Phone,
    PinEmpty,
    BarChart,
    Brush,
    CaseIcon,
    DoubleTick,
    Glasses,
    SecurityVerifiedIcon,
    BankIDIcon,
    HalfStar,
    LinkedIn,
    LogoGold,
    InfoIcon,
    CrossNew1,
    HeaderIcon,
    MobileHeaderIcon,
  },
  config: {
    apiHeaderJSON: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  storage: {
    token: 'token',
    userData: 'user',
    roles: 'roles',
  },
  role: {
    Admin: 'admin',
    GIGGER: 'gigger',
    COMPANY: 'company',
    COMPANY_USER: 'company_user',
    CV_VERIFICATION: 'cv_verification',
  },
  imageTypes: [
    'image/png',
    'image/jpg',
    'image/gif',
    'image/jpeg',
    'image/ppm',
    'image/tiff',
    'image/pgm',
    'image/pnm',
  ],
  inputTypes: {
    acceptDocTypes: [
      '.doc',
      '.docx',
      '.pdf',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    acceptFileTypes: [
      '.doc',
      '.docx',
      '.pdf',
      '.jpg',
      '.jpeg',
      '.png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/jpeg',
      'image/png',
      'image/jpg',
    ],
    acceptProfileImageTypes: ['image/jpeg', 'image/png', 'image/jpg', '.jpg', '.jpeg', '.png'],
    acceptDocumentTypes: '.pdf,.txt,.doc,.html,.htm,.docx,.odt,.ods,.ppt,.pptx,.xls,.xlsx',
    acceptImageTypes: '.png,.jpg,.gif,.jpeg,.ppm,.tiff,.pgm,.pnm',
    acceptImageMimeType: 'image/*',
  },
  FileTypes,
  toast: {
    SHORT_DELAY: 2000,
    DELAY: 5000,
    LONG_DELAY: 3500,
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
  },
  order: {
    BUY_CONTACT: 'buy-contact',
    CV_VERIFICATION: 'cv-check',
    REFERENCE_CHECK: 'reference_check',
    INTERVIEW: 'interview',
  },
  price: {
    'buy-contact': 100,
    'cv-check': 100,
    reference_check: '5 000',
    interview: '30 000',
  },
  status: {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    COMPLETED: 'completed',
    DRAFT: 'draft',
    OPEN: 'open',
    CANCELLED: 'cancelled',
    COMPLETE: 'complete',
    APPROVE: 'approve',
    DISAPPROVE: 'disapproved',
    IDPENDING: 'id-pending',
    IDDISAPPROVE: 'id-disapprove',
    EDITED: 'edited',
    FOLLOWEDUP: 'followed-up',
    NOTCHECKED: 'not-checked',
    NOTSTARTED: 'not-started',
    CV_APPROVED: 'cv-approved',
    CV_DISAPPROVED: 'cv-disapproved',
  },
  month: [
    { label: language.month_january, value: 'January' },
    { label: language.month_february, value: 'February' },
    { label: language.month_march, value: 'March' },
    { label: language.month_april, value: 'April' },
    { label: language.month_may, value: 'May' },
    { label: language.month_june, value: 'June' },
    { label: language.month_july, value: 'July' },
    { label: language.month_august, value: 'August' },
    { label: language.month_september, value: 'September' },
    { label: language.month_october, value: 'October' },
    { label: language.month_november, value: 'November' },
    { label: language.month_december, value: 'December' },
  ],
  docsCategory: [
    'cv',
    'personal_letter',
    'other_docs',
    'cv_verfication',
    'personal_letter_verification',
    'other_docs_verification',
    'Order',
    'F-skattsedel',
    'adddocs',
    'tax_slip',
    'profile_pic',
  ],
  footer: {
    ORGNUMBER: '556672-5528',
    EMAIL: 'hello@gigassembly.com support@gigassembly.com',
    PHONE: '+46 (0)73-523 93 45',
    ADDRESS: 'Blasieholmsgatan 4B',
    CITY: '111 48 Stockholm',
    EMAILHELLO: 'hello@gigassembly.com',
    GIGASSEMBLYAB: 'GigAssembly AB',
  },
  labels: {
    RAISINGTALENT: 'raising talent',
    VERYPOPULAR: 'very popular',
  },
  langugagesSupported: ['en', 'sv'],
  organisationNumber: 'orgNumber',
  personalNumber: 'personalNumber',
  email: 'email',
  phone: 'phone',
  zipcode: 'zipcode',
  OrganisationNumberLength: 11,
  PersonalNumberLength: 13,
  zipcodeLength: 5,
  CompanyProfile: 'CompanyProfile',
  GiggerProfile: 'GiggerProfile',
  company: language.company_super_user,
  company_user: language.common_user,
  orderTypes: [
    { 'buy-contact': language.customer_buy_contact_details },
    { reference_check: language.common_reference_check },
    { interview: language.common_interview },
    { hired_gigger: language.common_hired },
  ],
  fullTime: 'full_time',
  partTime: 'part_time',
  profileSummaryLength: 2000,
  customer: 'Customer',
  gigger: 'Gigger',
  fileSizeLimit: 800000,
  fileSizeLimitTenMB: 10485760,
  fileSizeLimitTwoMB: 2000000,
  toNovare: 'https://novare.se/',
  wpURL: {
    sv: {
      privacypolicy: 'integritetspolicy',
      termsAndConditions: 'allmanna-villkor',
      aboutUs: 'om-oss',
      aboutUsWithContact: 'om-oss/#contact-form-section',
      blog: 'blogg',
      optimizeyourprofile: 'sa-optimerar-du-din-profilstyrka-och-sokbarhet',
      forCompanies: 'foretag',
      forGiggers: 'for-konsulter',
      home: 'hem',
      faq: 'faq',
      administration: 'hitta-giggare/administration',
      economy: 'hitta-giggare/ekonomi',
      sale: 'hitta-giggare/forsaljning',
      hr: 'hitta-giggare/hr',
      marketing: 'hitta-giggare/marknadsforing',
      preLaunch: 'konsultregistrering',
      commonskills: 'hitta-giggare',
    },
    en: {
      privacypolicy: 'privacy-policy',
      termsAndConditions: 'terms-and-contidion',
      aboutUs: 'about-us',
      aboutUsWithContact: 'about-us/#contact-form-section',
      blog: 'blog',
      forCompanies: 'for-companies',
      forGiggers: 'for-consultants',
      home: 'home',
      faq: 'faq',
      administration: 'find-gigger/administration',
      economy: 'find-gigger/economy',
      sale: 'find-gigger/sale',
      hr: 'find-gigger/hr',
      marketing: 'find-gigger/marketing',
      preLaunch: 'registration',
    },
  },
  loginWithBankIdURL: '/login-withBankId',
  url: {
    customer: '/customer-signup/without-bankId',
    gigger: '/gigger-signup/without-bankId',
    hiredgigger: '/customer-hired',
  },
  startingYear: 1961,
  'not-checked': language.common_not_checked,
  'cv-approved': language.common_approved_cv,
  'cv-disapproved': language.common_disapproved,
  edited: language.common_edited,
  'followed-up': language.common_followed_up,
  'not-started': language.common_not_started,
  ORDER_TYPE: {
    'buy-contact': language.common_buy_contact,
    reference_check: language.common_reference_check,
    interview: language.common_interview,
    hired_gigger: language.common_hired,
  },
  purchasedGiggers: 'purchased-gigger',
  searchDelay: 500,
  sliderDelay: 100,
  from: 'from',
  to: 'to',
  dateLength: 10,
  nYears: 3,
  HTTP_STATUS: { OK: 200, CREATED: 201 },
  filter: {
    SKILLS: 'skills',
    CATEGORY: 'categories',
    JOBTITLE: 'job_titles',
    SYSTEMS: 'systems',
    workFromHome: 'Arbeta på distans',
  },
  english: 'en',
  swedish: 'sv',
  localStorageKeys: {
    userData: 'userData',
    token: 'token',
    firstLogin: 'first_login',
    language: 'language',
  },
  errorStatusCodes: [400, 415],
  billingAddress: 'billingAddress',
  emailExistsMessage: 'Email Id Already Exists',
  limitExceeded: 'Limit Exceeded',
  sort: {
    status: 'status',
    createdAt: 'created_at',
    name: 'name',
    accountOwner: 'account_owner.keyword',
    jobTitle: 'job_title.keyword',
    fullName: 'full_name.keyword',
    translatedStatus: 'translated_status.keyword',
  },
  statusOptions: [
    { label: language.common_approved, value: 'pending' },
    { label: language.common_disapprove, value: 'id-disapprove' },
  ],
  profile: {
    full_time: language.common_full_time,
    part_time: language.common_part_time,
  },
  slider: {
    min: 400,
    max: 3000,
  },
  options: {
    hourlyRateOptions: [...Array(53)].map((obj, index) => ({
      label: 400 + index * 50,
      value: 400 + index * 50,
    })),
    experienceOptions: [
      { label: `0-3 ${language.common_years}`, value: '0-3', isSelected: false },
      { label: `4-10  ${language.common_years}`, value: '4-10', isSelected: false },
      { label: `10+  ${language.common_years}`, value: '10+', isSelected: false },
    ],
    additionalDateOptions: [
      {
        label: language.common_exact_dates,
        value: '0',
      },
      {
        label: `+/- 1 ${language.common_week}`,
        value: '1',
      },
      {
        label: `+/- 2 ${language.common_weeks}`,
        value: '2',
      },
      {
        label: `+/- 3 ${language.common_weeks}`,
        value: '3',
      },
    ],
  },
  inputFields: {
    firstName: 'firstName',
    lastName: 'lastName',
    phone: 'phone',
    jobTitle: 'jobTitle',
    category: 'category',
    summary: 'summary',
    yearsOfExperience: 'yearsOfExperience',
    hourlyRate: 'hourlyRate',
    systems: 'systems',
    languages: 'languages',
    skills: 'skills',
    workAvailability: 'workAvailability',
    desiredCities: 'desiredCities',
    availableDate: 'availableDate',
    cv: 'cv',
    previousJobs: 'previousJobs',
    education: 'education',
    feedback: 'feedback',
  },
  input: {
    type: {
      email: 'email',
      text: 'text',
      number: 'number',
    },
    name: {
      email: 'email',
      role: 'role',
      firstName: 'firstName',
      lastName: 'lastName',
      billingReference: 'billingReference',
      orgNumber: 'orgNumber',
      companyName: 'companyName',
      repeatPassword: 'repeatPassword',
      newPassword: 'newPassword',
      personalNumber: 'personalNumber',
      limit: 'limit',
      invoiceEmail: 'invoiceEmail',
    },
  },
  error: {
    emailExistsMessage: 'Email Id Already Exists',
  },
  keys: {
    calendarCell: 'calendarCell',
    calendarRow: 'calendarRow',
    filterTitle: 'filterTitle',
    filterSkill: 'filterSkill',
    filterSystem: 'filterSystem',
    filterCategory: 'filterCategory',
    profileFullTime: 'profileFullTime',
    profilePartTime: 'profilePartTime',
  },
  translateWeek: {
    0: language.common_exact_dates,
    1: `+/- 1 ${language.common_week}`,
    2: `+/- 2 ${language.common_weeks}`,
    3: `+/- 3 ${language.common_weeks}`,
  },
  translateType: {
    job_titles: language.common_job_title,
    job_title: language.common_job_title,
    systems: language.common_systems,
    skills: language.common_skills,
    skill: language.common_skill,
    categories: language.common_category,
    category: language.common_category,
    secondary_job_title: language.common_secondary_title,
    order: language.common_orders,
  },
  translateLabels: {
    'raising talent': language.label_raising_talent,
    'very popular': language.label_very_popular,
  },
  translateOrderType: {
    'buy-contact': language.common_buy_contact,
    reference_check: language.common_reference_check,
    interview: language.common_interview,
    hired_gigger: language.common_hired,
  },
  yearsOfExperience: {
    junior: {
      start: 0,
      end: 3,
      label: '0-3',
    },
    experience: {
      start: 4,
      end: 10,
      label: '4-10',
    },
    senior: {
      start: 10,
      label: '10+',
    },
  },
  ONGOING: language.ongoing,
  workAvailability: 'work_availability',
  pageSize: 15,
  specialCharacterRegexPattern: /[^a-zA-Z 0-9]+/g,
  phoneNumberRegexPattern: /^[0-9 ()+-]*$/,
  NEW_PASSWORD: 'newPassword',
  REPEAT_PASSWORD: 'repeatPassword',
  defaultFilterData: [
    { name: 'job_title', label: '', id: '' },
    { name: 'category', label: '', id: '' },
    { name: 'systems', label: '', id: '' },
    { name: 'skills', label: '', id: '' },
    { name: 'experience', label: '', id: '' },
    { name: 'availabledate', label: '', id: '' },
    { name: 'cities', label: '', id: '' },
    { name: 'rate', label: '', id: '' },
    { name: 'language', label: '', id: '' },
    {
      name: 'work_availability', label: '', id: '', isFlexible: false,
    },
  ],
  yearMonthDateFormat: 'YYYY-MM-DD',
  INVALID_DATE: 'Invalid date',
  EXTERNAL: 'external',
  dateMonthYearFormat: 'DD.MM.YYYY',
  AVAILABLEDATE_FILTER_NAME: 'availabledate',
  RATE_FILTER_NAME: 'rate',
  Experience: 'experience',
};

export default Constants;
