import React, { Component } from 'react';
import validator from 'validator';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LoginBg, LoginBank } from '../../styles/Images';
import TextBox from '../../components/Input/TextBox';
import PasswordBox from '../../components/Input/PasswordBox';
import {
  FlexContainer,
  SectionLeft,
  SectionRight,
  A,
  LoginWrapper,
  Button,
  Title,
  SubTitle,
  ForgotPassword,
  Div,
  Form,
} from './styles';
import LoginButton from '../../components/Button/Button';
import Constants from '../../shared/constants';
import SignUpService from '../../services/SignUpService';
import AuthService from '../../services/AuthService';
import Config from '../../config';
import LogInModel from '../../models/Login';
import Loader from '../../components/Loader/Loader';
import {
  addHyphenToNumber,
  isLoggedIn,
  isAdmin,
  isGigger,
  isCustomer,
  isCvVerificationUser,
} from '../../shared/utils';
import { TextDiv } from '../../components/Input/styles';
import { ImageWrapper } from '../SignUp/styles';

const taiPasswordStrength = require('tai-password-strength');

class LogIn extends Component {
  authService = new AuthService(Config.apiBase);

  signUpService = new SignUpService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      bankId: false,
      isDisabled: true,
      loginError: false,
      loginErrorPN: false,
      personalNumber: '',
      email: '',
      password: '',
      formValid: {
        email_isValid: false,
        personalNo_isValid: false,
        password_isValid: false,
      },
      loading: false,
      loggedIn: isLoggedIn(),
    };
  }

  componentDidMount() {
    const { location: { data, pathname } } = this.props;
    if (data || pathname === Constants.loginWithBankIdURL) {
      this.setState({
        personalNumber: (data && data.personal_number) || '',
        bankId: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname } } = this.props;
    const { location: { pathname: prevPathname } } = prevProps;
    if (pathname !== prevPathname) {
      this.bankIdUpdate();
    }
  }

  /**
   * login to the account
   *
   * @async
   * @function handleLoginButton
   * @return {Promise<Array.<Object>>} The data from the URL.
   */

  handleLoginButton = async (event) => {
    const {
      location: { redirectTo = null, search = '' },
      history,
    } = this.props;
    const params = queryString.parse(search);
    const { userId = '', giggerId = '', feedback = '' } = params || {};
    const { email, password, isDisabled } = this.state;

    if (event) event.preventDefault();
    if (!isDisabled) {
      this.setState({ loading: true });

      const data = { email, password };

      this.loginModel = new LogInModel({});
      try {
        const response = await this.authService.signIn(this.loginModel.toJson(data));
        if (response && response.status !== 200) {
          this.setState({
            loading: false,
            loginError: true,
            email: email.toLowerCase(),
            password,
            isDisabled: true,
          });
        } else {
          const receiver = document.getElementById('receiver').contentWindow;
          const message = { isAuthenticated: !!localStorage.getItem('token') };

          receiver.postMessage(message, Config.wordpressUrl);

          if (redirectTo) {
            history.replace(redirectTo);
          }
          if (feedback) {
            history.push(`${Constants.routes.hiredGiggerFeedbackPrefix.url}/${userId}-${giggerId}`);
          } else if (isCustomer()) {
            history.push(Constants.routes.customerMyAccount.url);
          }
          if (isGigger()) {
            history.push(Constants.routes.giggerMyAccount.url);
          }
          if (isAdmin()) {
            history.push(Constants.routes.adminMyAccount.url);
          }
          if (isCvVerificationUser()) {
            history.push(Constants.routes.adminMyAccount.url);
          }
        }
      } catch (e) {
        this.setState({
          loading: false,
          loginError: true,
          email,
          password,
          isDisabled: true,
        });
      }
    }
  };

  /**
   * Redirects to login with BankId page
   */

  handlebankIdLogin = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.logInWithBankId.url, data: { bankId: true } });
  };

  /**
   * Redirects to SignUp page
   */

  handleJoinGig = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.signUp.url });
  };

  redirectToLogin=() => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.logIn.url });
  }

  /**
   * Redirects to forgot password page
   */

  handleForgotPassword = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.forgotPassword.url });
  };

  /**
   * handles change in input fields
   * @param {*} event
   */
  handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;

    this.setState({ [name]: value, loginError: false, loginErrorPN: false }, this.validate);
  };

  /**
   * restricts alphabets Personal number input
   * @param {*} evt
   */
  onKeyPress = (evt) => {
    const { bankId, isDisabled } = this.state;
    const theEvent = evt || window.event;
    let key;
    const { name } = theEvent.target;
    const { value } = theEvent.target;
    // Handle paste

    if (theEvent.type === 'paste') {
      key = evt.clipboardData.getData('text/plain');
    } else {
      // Handle key press

      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9 -]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    } else if (((value.replace(/-/g, '')).length) === Constants.PersonalNumberLength - 1) {
      theEvent.preventDefault();
      this.setState({ [name]: addHyphenToNumber(value, 8) }, this.validate);
    }

    if (theEvent.key === 'Enter' && bankId && !isDisabled) {
      this.loginBankId();
    }

    if (theEvent.key === 'Enter' && !bankId && !isDisabled) {
      this.handleLoginButton();
    }
    if (name === Constants.personalNumber && value.length
      >= Constants.PersonalNumberLength) theEvent.preventDefault();
  };

  /**
   * LogIn to account
   * @param {*} e
   */
  handleKeypress = (e) => {
    const { bankId, isDisabled } = this.state;

    if (e.key === 'Enter' && bankId && !isDisabled) {
      this.loginBankId();
    }
    if (e.key === 'Enter' && !bankId && !isDisabled) {
      this.handleLoginButton();
    }
  };

  /**
   * Login using BankId
   */

  loginBankId = async (event) => {
    const { formValid, personalNumber } = this.state;
    const { history } = this.props;
    this.setState({ loading: true });
    if (event) event.preventDefault();
    if (formValid.personalNo_isValid) {
      let data = {
        personal_number: personalNumber,
      };
      try {
        const response = await this.signUpService.loginBankID(data);
        const bankidData = { personal_number: personalNumber, orderRef: response.success.orderRef };
        data = { ...data, bankId: true };
        history.push({
          pathname: Constants.routes.bankIdAuthendicate.url,
          bankidData,
          data,
          redirectTo: 'Login',
        });
        this.setState({ loading: false });
      } catch (error) {
        this.setState({
          loginErrorPN: true,
          personalNumber,
          loading: false,
        });
      }
    }
  };

  /**
   * Validates the login form
   * @constant email {email} E-mail ID of the user
   * @constant password {password} Password of the user
   * @constant repeatPassword {password} Password of the user
   * @returns boolean value
   */

  validate() {
    const {
      bankId, formValid, email, password, personalNumber,
    } = this.state;

    let error = false;
    const passwordStrength = new taiPasswordStrength.PasswordStrength();
    const validation = passwordStrength.check(password);
    const doesntMeetRequirements = validation
      && validation.charsets
      && validation.charsets.upper
      && (validation.charsets.symbol || validation.charsets.punctuation)
      && validation.charsets.lower
      && validation.charsets.number
      && validation.passwordLength >= 8;
    if (!doesntMeetRequirements && !bankId) {
      formValid.password_isValid = false;
    } else {
      formValid.password_isValid = true;
    }

    if (!validator.isEmail(email) && !bankId) {
      formValid.email_isValid = false;
    } else {
      formValid.email_isValid = true;
    }

    if (
      !validator.isLength(personalNumber.toString(), {
        min: Constants.PersonalNumberLength,
        max: Constants.PersonalNumberLength,
      })
      && bankId
    ) {
      formValid.personalNo_isValid = false;
    } else {
      formValid.personalNo_isValid = true;
    }

    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });

    this.setState({ isDisabled: error });
    return { error, formValid };
  }

  bankIdUpdate() {
    const { bankId } = this.state;
    this.setState({ bankId: !bankId });
  }

  render() {
    const {
      bankId,
      personalNumber,
      isDisabled,
      loading,
      loginError,
      loginErrorPN,
      loggedIn,
      password,
      email,
    } = this.state;

    if (loggedIn) {
      const {
        location: { search = '' },
      } = this.props;
      const params = queryString.parse(search);
      const { userId = '', giggerId = '', feedback = '' } = params || {};
      let path = Constants.routes.logIn.url;
      if (feedback) {
        path = (`${Constants.routes.hiredGiggerFeedbackPrefix.url}/${userId}-${giggerId}`);
      }
      if (isGigger()) {
        path = Constants.routes.giggerMyAccount.url;
      }
      if (isCustomer()) {
        path = Constants.routes.customerMyAccount.url;
      }
      if (isAdmin() || isCvVerificationUser()) {
        path = Constants.routes.adminMyAccount.url;
      }

      return (
        <Redirect
          to={{
            pathName: path,
          }}
        />
      );
    }

    return (
      <FlexContainer className="bg-white-1 justify-content-center">
        <SectionLeft>
          {loading ? (
            <Loader height="24px" marginTop="24px" width="100px" />
          ) : (
            <>
              {bankId ? (
                <LoginWrapper className="row m-0" maxWidth="330px">
                  <A left onClick={this.handleJoinGig} role="button">
                    <FormattedMessage id="login_join_gigassembly" defaultMessage="Join Gigassembly" />
                  </A>
                </LoginWrapper>
              ) : (
                <LoginWrapper className="row mb-3 m-lg-0" maxWidth="330px">
                  <A onClick={this.handlebankIdLogin} role="button">
                    <FormattedMessage id="login_login_with_bankid" defaultMessage="Login with BankID" />
                  </A>
                </LoginWrapper>
              )}
            </>
          )}
          <>
            {bankId ? (
              <LoginWrapper maxWidth="330px">
                {loading ? (
                  <Loader height="60px" width="200px" />
                ) : (
                  <Title>
                    <FormattedMessage id="login_welcome_back" defaultMessage="Welcome back" />
                  </Title>
                )}
                {loading ? (
                  <Loader width="250px" />
                ) : (
                  <SubTitle>
                    <FormattedMessage id="login_login_with_bankid" defaultMessage="Log in with BankID" />
                  </SubTitle>
                )}
                {!loading && loginErrorPN && (
                <TextDiv className="mb-3">
                  <img
                    src={Constants.icons.ErrorIcon}
                    alt="icon"
                    className="mr-1"
                    width="12px"
                    height="12px"
                  />
                  <span><FormattedMessage id="login_with_bankId_error" defaultMessage="Invalid personal ID number" /></span>
                </TextDiv>
                )}
                <Form onSubmit={this.loginBankId}>
                  {loading ? (
                    <Loader height="60px" width="346px" />
                  ) : (
                    <Div>
                      <TextBox
                        label={<FormattedMessage id="common_personal_number" defaultMessage="Personal ID number" />}
                        type="numeric"
                        placeholder="XXXXXXXX-YYYY"
                        name="personalNumber"
                        value={personalNumber}
                        onChange={this.handleChange}
                        onKeyPress={this.onKeyPress}
                        errorMessage={
                          <FormattedMessage id="error_personal_no_message" defaultMessage="Enter a personal ID number" />
                      }
                        rule={{
                          method: validator.isLength,
                          validWhen: true,
                          args: [{
                            min: Constants.PersonalNumberLength,
                            max: Constants.PersonalNumberLength,
                          }],
                        }}
                      />
                    </Div>
                  )}
                  {loading ? (
                    <Loader height="40px" width="346px" />
                  ) : (
                    <LoginButton
                      disabled={isDisabled}
                      name={
                        <FormattedMessage id="login_sign_in_with_bankid" defaultMessage="Sign in with Mobile BankID" />
                    }
                      type="signIn"
                      onClick={this.loginBankId}
                    />
                  )}
                </Form>
                <>
                  {loading ? (
                    <Loader height="24px" width="280px" margin="24px" />
                  ) : (
                    <ForgotPassword onClick={this.redirectToLogin} role="button">
                      <FormattedMessage id="login_login_with_password" defaultMessage="Log in with password" />
                    </ForgotPassword>
                  )}
                </>
              </LoginWrapper>
            ) : (
              <LoginWrapper maxWidth="330px">
                {loading ? (
                  <Loader height="60px" width="200px" />
                ) : (
                  <Title>
                    <FormattedMessage id="login_welcome" defaultMessage="Welcome" />
                  </Title>
                )}
                {loading ? (
                  <Loader width="250px" />
                ) : (
                  <SubTitle>
                    <FormattedMessage
                      id="login_login_with_email_and_password"
                      defaultMessage="Login with e-mail and password"
                    />
                  </SubTitle>
                )}
                {!loading && loginError && (
                <TextDiv className="mb-3">
                  <img
                    src={Constants.icons.ErrorIcon}
                    alt="icon"
                    className="mr-1"
                    width="12px"
                    height="12px"
                  />
                  <span><FormattedMessage id="login_error" defaultMessage="Invalid e-mail and password combination" /></span>
                </TextDiv>
                )}
                {loading && <Loader height="20px" width="166px" />}
                <Form onSubmit={this.handleLoginButton}>
                  {loading ? (
                    <Loader height="40px" width="346px" />
                  ) : (
                    <TextBox
                      label={<FormattedMessage id="common_email" defaultMessage="E-mail" />}
                      type="email"
                      className="mb-4"
                      name="email"
                      forFilter={false}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeypress}
                      value={email}
                      errorMessage={
                        <FormattedMessage id="error_email_message" defaultMessage="Enter a valid e-mail address" />
                    }
                      rule={{
                        method: validator.isEmail,
                        validWhen: true,
                      }}
                    />
                  )}
                  {loading && <Loader height="20px" width="166px" />}
                  {loading ? (
                    <Loader height="40px" />
                  ) : (
                    <PasswordBox
                      label={<FormattedMessage id="common_password" defaultMessage="Password" />}
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeypress}
                      errorMessage={(
                        <FormattedMessage
                          id="error_password_validation_message"
                          defaultMessage="Enter a valid password"
                        />
                    )}
                      rule={{
                        method: validator.isEmpty,
                        validWhen: false,
                      }}
                    />
                  )}
                  {loading ? (
                    <Loader height="40px" width="346px" />
                  ) : (
                    <Button login disabled={isDisabled} type="submit">
                      <FormattedMessage id="login_login" defaultMessage="Login" />
                    </Button>
                  )}
                  {loading ? (
                    <Loader height="24px" width="280px" margin="24px" />
                  ) : (
                    <ForgotPassword onClick={this.handleForgotPassword} role="button">
                      <FormattedMessage id="login_forgot_password" defaultMessage="Forgot password?" />
                    </ForgotPassword>
                  )}
                </Form>
              </LoginWrapper>
            )}
          </>
        </SectionLeft>
        <SectionRight>
          {loading ? (
            <Loader height="1441px" />
          ) : (
            <>
              <ImageWrapper
                backgroundImage={bankId ? LoginBank : LoginBg}
                backgroundSize="cover !important"
                height="783px"
              />
            </>
          )}
        </SectionRight>
      </FlexContainer>
    );
  }
}

export default LogIn;
