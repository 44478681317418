const Routes = {
  home: { url: '/' },
  noPage: { url: '/welcome' },
  logIn: { url: '/login' },
  logInWithBankId: { url: '/login-withBankId' },
  logOut: { url: '/logout' },
  signUp: { url: '/signup' },
  customerSignUp: { url: '/customer-signup' },
  customerSignUpWithOutBankId: { url: '/customer-signup/without-bankId' },
  giggerSignUp: { url: '/gigger-signup' },
  giggerSignUpWithOutBankId: { url: '/gigger-signup/without-bankId' },
  forgotPassword: { url: '/forgot-password' },
  resetPassword: { url: '/reset-password' },
  authendicationFailure: { url: '/authendication-failure' },
  bankIdAuthendicate: { url: '/bankId-authendicate' },
  signUpconfirmationPage: { url: '/sigup-confirmationPage' },
  giggerMyAccount: { url: '/gigger-myaccount' },
  customerMyAccount: { url: '/customer-myaccount' },
  giggerUserDetails: { url: '/gigger-userdetails' },
  customerUserDetails: { url: '/customer-userdetails' },
  giggerCompanyDetails: { url: '/gigger-companydetails' },
  customerCompanyDetails: { url: '/customer-companydetails' },
  giggerDetails: { url: '/details/:username-:id' },
  customerOrders: { url: '/customer-orders' },
  giggerMyGiggs: { url: '/gigger-mygiggs' },
  myprofile: { url: '/profile/:firstname-:lastname-:id' },
  customerMyGiggers: { url: '/customer-mygiggers' },
  manageUsers: { url: '/customer-manageusers' },
  hiredGiggerFeedbackPrefix: { url: '/customer-feedback' },
  hiredGiggerFeedback: { url: '/customer-feedback/:userId-:giggerId' },
  hiredDatesPrefix: {
    url: '/customer-hired',
  },
  hiredDates: { url: '/customer-hired/:userId-:giggerId' },
  giggerList: { url: '/gigger-list' },
  deleteAccount: { url: '/customer-deleteaccount' },
  customerDetailView: { url: '/gigger-:name/:id' },
  portal: { url: '/admin/portal' },
  users: { url: '/admin/portal/users-list' },
  orders: { url: '/admin/portal/order-list' },
  gigger: { url: '/admin/portal/gigger' },
  giggers: { url: '/admin/portal/gigger-list' },
  filter: { url: '/admin/portal/filter-list' },
  feedback: { url: '/admin/portal/feedback-list' },
  company: { url: '/admin/portal/company' },
  companies: { url: '/admin/portal/company-list' },
  adminGiggerDetailView: { url: '/admin/portal/gigger/:giggername/profile' },
  adminCompanyDetailView: { url: '/admin/portal/company/:companyname/profile' },
  adminMyAccount: { url: '/admin/my-account' },
  adminUserDetails: { url: '/admin/user-details' },
  approveAccountDelete: { url: '/approve-delete/:id' },
  gigassemblyWPDevelopment: { url: 'https://gigassembly03.wpengine.com' },
  gigassemblyWPStaging: { url: 'https://gigassembly02.wpengine.com' },
  gigassemblyWPProduction: { url: 'https://gigassembly.com' },
  linkedInExportCvTutorial: { url: 'https://www.linkedin.com/help/linkedin/answer/4281/save-a-profile-as-a-pdf?lang=en', host: 'linkedin.com' },
  notFound: { url: '/not-found' },
  thankYouForFeedback: { url: '/thank-you' },
  thankYouForHiring: { url: '/thank-you-for-hiring' },
  CompanyFeedbackMessage: { url: '/company-feedback-message' },
  giggerFeedbackForm: { url: '/giggers/:gigger_id/request-external-feedback' },
};

export default Routes;
