import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthService from '../services/AuthService';
import Constants from './constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    location, path, exact, computedMatch,
  } = rest;
  const authService = new AuthService();
  const paths = [
    Constants.routes.logIn.url,
    Constants.routes.home.url,
    Constants.routes.forgotPassword.url,
    Constants.routes.resetPassword.url,
    Constants.routes.signUp.url,
    // Constants.routes.customerSignUp.url,
    Constants.routes.giggerSignUp.url,
    Constants.routes.customerDetailView.url,
    Constants.routes.noPage.url,
    Constants.routes.notFound.url,
    Constants.routes.logOut.url,
    Constants.routes.authendicationFailure.url,
    Constants.routes.bankIdAuthendicate.url,
    Constants.routes.giggerList.url,
    Constants.routes.gigassemblyWPDevelopment.url,
    Constants.routes.gigassemblyWPStaging.url,
    Constants.routes.gigassemblyWPProduction.url,
    Constants.routes.logInWithBankId.url,
    Constants.routes.customerSignUpWithOutBankId.url,
    Constants.routes.giggerSignUpWithOutBankId.url,
    Constants.routes.giggerFeedbackForm.url,
    Constants.routes.thankYouForFeedback.url,
  ];
  const avaliablePaths = Object.values(Constants.routes).map((o) => o.url);
  return (
    <Route
      location={location}
      exact={exact}
      path={path}
      computedMatch={computedMatch}
      render={(props) => (authService.isAuthenticated() === true
        || paths.includes(path) ? (
          <Component
            history={props.history}
            location={props.location}
            match={props.match}
            staticContext={props.staticContext}
          />
        ) : (
          avaliablePaths.includes(path)
            ? (
              <Redirect
                to={{
                  pathname: Constants.routes.logIn.url,
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: Constants.routes.notFound.url,
                }}
              />
            )
        ))}
    />
  );
};

export default PrivateRoute;
